import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';
import type MediaService from 'ember-responsive';
import { isMobile } from 'ember-smily-base/utils/application';
import type AccountModel from 'smily-admin-ui/models/account';
import type SessionService from 'smily-admin-ui/services/session-service';

export default class RouteAbility extends Ability<never> {
  @service abilities!: AbilitiesService;
  @service media!: MediaService;
  @service session!: SessionService;

  get account(): AccountModel | undefined {
    return this.session.account;
  }

  get isFree(): boolean {
    return !!this.account?.isFree;
  }

  get isSmily(): boolean {
    return !!this.account?.isSmily;
  }

  get onboardingInProgress(): boolean {
    return !!this.account?.onboardingInProgress;
  }

  get shouldLockMenu(): boolean {
    return (
      (this.isFree || this.onboardingInProgress) && !this.session.hasAnyRental
    );
  }

  get isSecondStep(): boolean {
    return this.session.onboarding?.currentStep === 2;
  }

  get isUpgradingFromFree(): boolean {
    return this.isFree && this.onboardingInProgress && this.isSecondStep;
  }

  get isAdminOrMember(): boolean {
    return this.session.role === 'admin' || this.session.role === 'member';
  }

  get isSuperuser(): boolean {
    return !!this.session.user?.superuser;
  }

  get isChannelImportInProgress(): boolean {
    return this.session.channelImportInProgress;
  }

  get isNotDisabledByOnboarding() {
    return !this.shouldLockMenu && !this.isChannelImportInProgress;
  }

  get canShowOnboarding(): boolean {
    return !!this.onboardingInProgress;
  }

  get canAccessOnboarding(): boolean {
    return this.canShowOnboarding;
  }

  get canShowDashboard(): boolean {
    return (
      this.abilities.can('access dashboard release') &&
      (!this.isFree || this.onboardingInProgress)
    );
  }

  get canAccessDashboard(): boolean {
    return this.canShowDashboard && this.isNotDisabledByOnboarding;
  }

  get canShowTasks() {
    return this.abilities.can('access task management feature');
  }

  get canAccessTasks() {
    return this.canShowTasks && this.isNotDisabledByOnboarding;
  }

  get canShowTasksTemplates() {
    return this.abilities.can('view task-management-template');
  }

  get canAccessTasksTemplates() {
    return (
      this.canShowTasks &&
      !this.shouldLockMenu &&
      !this.isChannelImportInProgress
    );
  }

  get canAccessTasksTaskManage() {
    return this.abilities.can('delete task-management-task-item');
  }

  get canShowPerformance() {
    return (
      !isMobile(this.media) &&
      this.abilities.can('view scoreboard-rental') &&
      (this.isSuperuser || this.abilities.can('access scoreboard release'))
    );
  }

  get canAccessPerformance() {
    return this.canShowPerformance && this.isNotDisabledByOnboarding;
  }

  get canShowInbox(): boolean {
    return (
      (this.abilities.can('access inbox feature') &&
        this.abilities.can('view inbox-conversation')) ||
      this.onboardingInProgress
    );
  }

  get canAccessInbox(): boolean {
    return this.canShowInbox && this.isNotDisabledByOnboarding;
  }

  get canShowCalendar(): boolean {
    return this.abilities.can('access calendars feature');
  }

  get canAccessCalendar(): boolean {
    return this.canShowCalendar && !this.isChannelImportInProgress;
  }

  get canShowBookings(): boolean {
    return this.abilities.can('access bookings feature');
  }

  get canAccessBookings(): boolean {
    return this.canShowBookings && this.isNotDisabledByOnboarding;
  }

  get canShowOldBookings(): boolean {
    return !this.canShowBookings && !this.canShowCalendar;
  }

  get canAccessOldBookings(): boolean {
    return this.canShowOldBookings && this.isNotDisabledByOnboarding;
  }

  get canShowOldBookingsWithCalendarName(): boolean {
    return this.canShowBookings && !this.canShowCalendar;
  }

  get canAccessOldBookingsWithCalendarName(): boolean {
    return (
      this.canShowOldBookingsWithCalendarName && this.isNotDisabledByOnboarding
    );
  }

  get canAccessBookingsManagement() {
    return this.abilities.can('access bookings management release');
  }

  get canShowOrders(): boolean {
    return this.abilities.can('access payables test page release');
  }

  get canAccessOrders(): boolean {
    return this.canShowOrders;
  }

  get canShowClients(): boolean {
    return this.onboardingInProgress || (this.isAdminOrMember && !this.isFree);
  }

  get canAccessClients(): boolean {
    return this.canShowClients && this.isNotDisabledByOnboarding;
  }

  get canShowFinance(): boolean {
    return (
      this.isAdminOrMember &&
      this.abilities.can('access finance feature') &&
      (this.abilities.can('access finance publicly visible release') ||
        this.isSuperuser)
    );
  }

  get canAccessFinance(): boolean {
    return this.canShowFinance && !this.isChannelImportInProgress;
  }

  get canAccessFinanceGrossEarnings() {
    return this.canAccessFinance && this.isSmily;
  }

  get canShowDiscounts(): boolean {
    if (this.abilities.cannot('access discount codes release')) {
      return false;
    }

    return (
      this.isAdminOrMember &&
      this.abilities.can('access discount codes feature')
    );
  }

  get canAccessDiscounts(): boolean {
    return this.canShowDiscounts && !this.isChannelImportInProgress;
  }

  get canShowRentals(): boolean {
    return this.isAdminOrMember;
  }

  get canAccessRentals(): boolean {
    return this.canShowRentals && !this.isChannelImportInProgress;
  }

  get canShowInquiries(): boolean {
    return !isMobile(this.media) && this.isAdminOrMember;
  }

  get canAccessInquiries(): boolean {
    return this.canShowInquiries && this.isNotDisabledByOnboarding;
  }

  get canShowReviews(): boolean {
    return (
      this.isAdminOrMember &&
      (this.isSuperuser || this.onboardingInProgress || !this.isFree)
    );
  }

  get canAccessReviews(): boolean {
    return this.canShowReviews && this.isNotDisabledByOnboarding;
  }

  get canAccessHostReviewAutomation(): boolean {
    return this.abilities.can('access host review automation feature');
  }

  get canShowApps(): boolean {
    return (
      !isMobile(this.media) &&
      (this.abilities.can('access applications feature') || this.isSuperuser)
    );
  }

  get canAccessApps(): boolean {
    return this.canShowApps;
  }

  get canShowSettings(): boolean {
    return !!this.session.owner && this.isSmily;
  }

  get canAccessSettings(): boolean {
    return this.canShowSettings && !this.isChannelImportInProgress;
  }

  get canShowProfile() {
    return !isMobile(this.media) || this.isSmily;
  }

  get canAccessProfile(): boolean {
    return this.canShowProfile && !this.isChannelImportInProgress;
  }

  get canAccessSettingsBilling(): boolean {
    return !this.account?.isFree;
  }

  get canAccessEditSettings(): boolean {
    return !this.onboardingInProgress || this.isUpgradingFromFree;
  }

  get canAccessXSettingsBilling() {
    return !!this.session.owner;
  }

  get canAccessXSettingsSplitPayments() {
    return (
      this.isAdminOrMember &&
      this.abilities.can('access split payments feature')
    );
  }

  get canAccessAccountSuspended() {
    return !this.abilities.can('access app in account');
  }

  get canAccessAuthLogout() {
    return !!this.session.isAuthenticated;
  }
}
