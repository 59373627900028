import { dateString } from 'ember-smily-base/utils/date';
import type { QueryParam } from 'smily-admin-ui/controllers/performance';
import {
  getTransformedDate,
  getTimeVectors,
} from 'smily-admin-ui/utils/performance';

const TODAY = new Date();

export default function buildQuery(
  params: Record<QueryParam, string | undefined>,
  isCompare?: boolean,
) {
  const [startVector, endVector] = getTimeVectors(params.period!);

  const baseDate = isCompare
    ? getTransformedDate(TODAY, { unit: 'year', value: -1 })
    : TODAY;
  const startDate = getTransformedDate(baseDate, startVector);
  const endDate = getTransformedDate(baseDate, endVector);

  const filters = Object.entries(params)
    .map(([param, value]) => {
      if (param === 'period') {
        return `filter%5Bstart-date%5D=${dateString(
          startDate,
        )}&filter%5Bend-date%5D=${dateString(endDate)}`;
      } else if (param === 'compare' || !value) {
        return undefined;
      } else {
        return `filter%5B${param}%5D=${value}`;
      }
    })
    .filter(Boolean);
  const meta = ['meta%5B%5D=value', 'meta%5B%5D=points'];

  return [...filters, ...meta].join('&');
}
