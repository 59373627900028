import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export enum QueryParam {
  Period = 'period',
  Compare = 'compare',
  Source = 'source',
  RentalsTags = 'rentalsTags',
  Rental = 'rental',
  Destinations = 'destinations',
}

export default class PerformanceController extends Controller {
  queryParams = Object.values(QueryParam);

  @tracked period = '';
  @tracked compare = '';
  @tracked source?: string;
  @tracked rentalsTags?: string;
  @tracked rental?: string;
  @tracked destinations?: string;

  setup() {
    if (!this.period) {
      this.period = 'last_3m';
    }
  }

  @action
  changeQueryParam(key: QueryParam, value: string): void {
    this[key] = value;
  }
}

declare module '@ember/controller' {
  interface Registry {
    performance: PerformanceController;
  }
}
