import { LinkTo } from '@ember/routing';
import BsAlert from 'ember-bootstrap/components/bs-alert';
import formatDate from 'ember-intl/helpers/format-date';
import t from 'ember-intl/helpers/t';
import RouteTemplate from 'ember-route-template';
import List from 'ember-smily-base/components/list';
import ListingScreen from 'ember-smily-base/components/listing-screen';
import formatMoney from 'ember-smily-base/helpers/format-money';
import eq from 'ember-truth-helpers/helpers/eq';
import not from 'ember-truth-helpers/helpers/not';
import FinanceBookingPayoutItem from 'smily-admin-ui/components/finance/booking-payout-item';
import FinanceListingScreenTabs from 'smily-admin-ui/components/finance/listing-screen-tabs';
import FinanceUpcomingPayoutsItem from 'smily-admin-ui/components/finance/upcoming-payouts-item';
import type FinanceUpcomingPayoutsController from 'smily-admin-ui/controllers/finance/upcoming-payouts';
import type FinanceUpcomingPayoutsRoute from 'smily-admin-ui/routes/finance/upcoming-payouts';
import TemplatesFinancePayoutsComponent from 'smily-admin-ui/templates/finance/-payouts';

class TemplatesFinanceUpcomingPayouts extends TemplatesFinancePayoutsComponent<
  'upcoming-payout',
  FinanceUpcomingPayoutsController,
  FinanceUpcomingPayoutsRoute
> {
  <template>
    <ListingScreen
      @model={{if
        this.needsOnboardingCompletion
        this.emptyOptions
        @model.payouts
      }}
      @mobileComponent={{FinanceUpcomingPayoutsItem}}
      @page={{@controller.page}}
      @changePage={{@controller.changePage}}
      @onModalOpen={{this.setModalModel}}
    >
      <:tabs>
        <FinanceListingScreenTabs />
      </:tabs>

      <:information>
        {{#if this.needsOnboardingCompletion}}
          <BsAlert @type='warning' @dismissible={{false}} class='mb-0'>
            {{#if (eq this.session.onboarding.currentStep 4)}}
              {{t 'finance.warning_complete_onboarding_step_4'}}
            {{else}}
              {{t 'finance.warning_complete_onboarding'}}
            {{/if}}
          </BsAlert>
        {{else if (not this.session.sessionInfo.financeDataAvailable)}}
          <BsAlert @type='warning' @dismissible={{false}} class='mb-0'>
            {{t 'finance.activate_smilypay'}}
          </BsAlert>
        {{/if}}
      </:information>

      <:modal as |modal|>
        {{#if this.selectedPayout}}
          <modal.header>
            <div class='d-flex-flex-column'>
              <div>
                <div>
                  {{this.selectedPayout.paymentGateway.nameWithRole}}
                </div>

                {{formatDate
                  this.selectedPayout.estimatedPayoutDate
                  format='short'
                }}
                •
                {{t
                  'internals.model_counters.booking'
                  counter=this.selectedPayout.bookingPayouts.length
                }}
              </div>

              <div class='fw-semibold'>
                {{formatMoney
                  this.selectedPayout.amount
                  this.selectedPayout.currency
                }}
              </div>
            </div>
          </modal.header>

          <modal.body class='h-0 d-flex flex-column p-0'>
            <div class='h-0 flex-1 overflow-auto'>
              <List @linked={{true}} as |Item|>
                {{#each this.selectedPayout.bookingPayouts as |bookingPayout|}}
                  <Item>
                    <LinkTo
                      @route='bookings.booking'
                      @model={{bookingPayout.booking.id}}
                      class='link-unstyled p-3'
                    >
                      <FinanceBookingPayoutItem
                        @bookingPayout={{bookingPayout}}
                      />
                    </LinkTo>
                  </Item>
                {{/each}}
              </List>
            </div>

            <div class='d-flex flex-column p-3 border-top'>
              <div class='fw-semibold'>
                {{t 'finance.estimated_arrival'}}
              </div>

              <div>
                {{formatDate
                  this.selectedPayout.estimatedArrivalOn
                  format='short'
                }}
              </div>
            </div>
          </modal.body>
        {{/if}}
      </:modal>
    </ListingScreen>
  </template>
}

export default RouteTemplate(TemplatesFinanceUpcomingPayouts);
