import type { TimeVector } from 'smily-admin-ui/utils/performance';

export default function getTimeVectors(
  period: string,
): [TimeVector | null, TimeVector | null] {
  switch (period) {
    case 'next_year':
      return [
        { unit: 'year', value: 1, isStartOfUnit: true },
        { unit: 'year', value: 1, isEndOfUnit: true },
      ];
    case 'year_from_today':
      return [null, { unit: 'year', value: 0, isEndOfUnit: true }];
    case 'next_6m':
      return [null, { unit: 'month', value: 5, isEndOfUnit: true }];
    case 'next_3m':
      return [null, { unit: 'month', value: 2, isEndOfUnit: true }];
    case 'next_30d':
      return [null, { unit: 'day', value: 29 }];
    case 'this_month':
      return [
        { unit: 'month', value: 0, isStartOfUnit: true },
        { unit: 'month', value: 0, isEndOfUnit: true },
      ];
    case 'next_14d':
      return [null, { unit: 'day', value: 13 }];
    case 'next_7d':
      return [null, { unit: 'day', value: 6 }];
    case 'this_week':
      return [
        { unit: 'day', value: 0, isStartOfUnit: true },
        { unit: 'day', value: 0, isEndOfUnit: true },
      ];
    case 'today':
      return [null, null];
    case 'yesterday':
      return [
        { unit: 'day', value: -1 },
        { unit: 'day', value: -1 },
      ];
    case 'last_7d':
      return [{ unit: 'day', value: -6 }, null];
    case 'last_14d':
      return [{ unit: 'day', value: -13 }, null];
    case 'last_30d':
      return [{ unit: 'day', value: -29 }, null];
    case 'last_3m':
      return [{ unit: 'month', value: -2, isStartOfUnit: true }, null];
    case 'last_6m':
      return [{ unit: 'month', value: -5, isStartOfUnit: true }, null];
    case 'year_to_today':
      return [{ unit: 'year', value: 0, isStartOfUnit: true }, null];
    case 'last_year':
      return [
        { unit: 'year', value: -1, isStartOfUnit: true },
        { unit: 'year', value: -1, isEndOfUnit: true },
      ];
    default:
      return [null, null];
  }
}
