import type { TimeVector } from 'smily-admin-ui/utils/performance';
import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

export default function getTransformedDate(
  baseDate: Date,
  timeVector: TimeVector | null,
) {
  if (!timeVector) {
    return baseDate;
  }

  let date = baseDate;

  switch (timeVector.unit) {
    case 'day':
      date = addDays(baseDate, timeVector.value);

      if (timeVector.isStartOfUnit) {
        date = startOfWeek(date);
      } else if (timeVector.isEndOfUnit) {
        date = endOfWeek(date);
      }
      break;
    case 'month':
      date = addMonths(baseDate, timeVector.value);

      if (timeVector.isStartOfUnit) {
        date = startOfMonth(date);
      } else if (timeVector.isEndOfUnit) {
        date = endOfMonth(date);
      }
      break;
    case 'year':
      date = addYears(baseDate, timeVector.value);

      if (timeVector.isStartOfUnit) {
        date = startOfYear(date);
      } else if (timeVector.isEndOfUnit) {
        date = endOfYear(date);
      }
      break;
  }

  return date;
}
