import { service } from '@ember/service';
import type StoreService from 'ember-smily-base/services/store';
import type { ModelFor, Transition } from 'ember-smily-base/utils/routing';
import type PerformanceController from 'smily-admin-ui/controllers/performance';
import { QueryParam } from 'smily-admin-ui/controllers/performance';
import type CacheService from 'smily-admin-ui/services/cache';
import { BaseRoute } from 'smily-admin-ui/utils/routing';

export default class PerformanceRoute extends BaseRoute {
  @service cache!: CacheService;
  @service store!: StoreService;

  queryParams = Object.fromEntries(
    Object.values(QueryParam).map((param) => [param, { refreshModel: true }]),
  );

  checkAbilities = true;
  requiredRouteAbility = 'access performance route';

  beforeModel(transition: Transition) {
    super.beforeModel(transition);

    this.cache.loadDestinations();

    return this.store.loadModelsById([
      { modelName: 'source', ids: transition.to.queryParams.source },
      { modelName: 'rental', ids: transition.to.queryParams.rental },
      { modelName: 'rentals-tag', ids: transition.to.queryParams.rentalsTags },
    ]);
  }

  model() {
    // need to return something so that query param change actually refreshes the model
    return null;
  }

  setupController(
    controller: PerformanceController,
    model: ModelFor<this>,
    transition: Transition,
  ) {
    super.setupController(controller, model, transition);
    controller.setup();
  }
}
